// old Description

import React from 'react'
import Lines from './Lines'
import Button from './Button'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  s,
  colors,
  alpha,
  sImage,
  sGatsbyImage,
  sDecoPath,
  sButtons,
  sTextGradient,
  sHtmlTemplateBody,
} from '../style'
import alt from '../content/alt'
import ImageCredit from './ImageCredit'

const TemplateBody = ({
  title,
  body,
  image,
  button,
  buttons,
  children,
  imageCredits,
}) => {
  return (
    <section css={sSection}>
      <div css={sDecoPath}>
        <Lines />
      </div>
      <div css={sOuter}>
        <p css={sTitle}>{title}</p>
        <div css={sImage}>
          <GatsbyImage
            image={image}
            style={sGatsbyImage}
            alt={title + alt}
            imgStyle={{ borderRadius: 'inherit' }}
          />
          <ImageCredit>{imageCredits}</ImageCredit>
        </div>

        <article
          css={[sArticle, sHtmlTemplateBody]}
          dangerouslySetInnerHTML={{ __html: body }}></article>
        {children}
        {button && (
          <Button glow link={button.link}>
            {button.text}
          </Button>
        )}
        {buttons && (
          <div css={sButtons}>
            {buttons.map(
              (btn, id) =>
                btn && (
                  <React.Fragment key={id}>
                    <Button
                      glow={btn.glow}
                      reactive={btn.reactive}
                      dimmed={btn.dimmed}
                      link={btn.link}
                      action={btn.action}>
                      {btn.text}
                    </Button>
                  </React.Fragment>
                )
            )}
          </div>
        )}
      </div>
    </section>
  )
}

const sSection = {
  zIndex: 5,
  background: `linear-gradient(to bottom, ${colors.greyDarker}, ${colors.greyDark})`,
  color: colors.white,
  position: 'relative',
  boxShadow: `0px 16px 64px ${colors.black.concat(alpha[30])}`,
  [s.sm_down]: {
    position: 'relative',
    left: 0,
    top: -1,
    borderRadius: '0 0 2rem 2rem',
  },
  [s.md]: {
    borderRadius: '0px 0px 128px 128px',
  },
}

const sOuter = {
  width: '100vw',
  maxWidth: 960,
  padding: '10rem 2rem 10rem',
  margin: '0 auto',
  [s.xs]: {
    padding: '128px 32px 32px',
  },
  [s.md]: {
    padding: '10rem 4rem 10rem',
  },
  zIndex: 1,
  position: 'relative',
  top: 0,
  left: 0,
}

const sTitle = {
  ...sTextGradient,
  fontWeight: 400,
  fontSize: '3rem',
  letterSpacing: '-0.05em',
  paddingBottom: '4rem',
  lineHeight: '1.618em',
  [s.sm_down]: {
    fontSize: '2rem',
  },
}

const sH2 = {
  ...sTextGradient,
  margin: 0,
  zIndex: 2,
  lineHeight: '1.35em',
  width: 'max-content',
  maxWidth: '100%',
  textAlign: 'left',
  fontSize: '3rem',
  letterSpacing: '-0.05em',
  [s.sm_down]: {
    fontSize: '2.5rem',
  },
}

const sP = {
  lineHeight: '2em',
  [s.sm_down]: {
    fontSize: '1rem',
  },
  [s.md]: {
    fontSize: '1.25rem',
    span: {
      width: '60%',
    },
  },

  color: colors.white.concat(alpha[60]),
  strong: {
    color: colors.white.concat(alpha[100]),
    fontWeight: 700,
  },

  margin: '4rem 0',
}

const sArticle = {
  p: sP,
  h2: sH2,
}

export default TemplateBody
