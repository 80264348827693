import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { sCenteredSpaced } from '../style'
import Button from '../components/Button'
import { useFormContext } from '../context/form.context'

export default function IntermodalTemplate({ data }) {
  const {
    name,
    title,
    slug,
    keywords,
    image,
    imagecontent,
    body,
    description,
    createdAt,
    updatedAt,
  } = data.service

  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const imageData = getImage(image)

  const buttonIntro = [{
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
      zh: '阅读更多',
    },
    action: 'SCROLL',
  }].map(button => ({
    text: button.text[lang],
    action: button.action
  }));

  const buttonQuote = {
    text: 'Zleć transport',
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonParent = {
    text: 'Wszystkie kraje',
    link: '/transport-intermodalny/',
    dimmed: true,
  }

  if (typeof window !== `undefined`) {
    const currentUrl = window.location.hash;
    if (currentUrl == "#modal-open") {
      setFormOpen(true);
      window.location.hash = 'modal-shown';
    }
  }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={description}
        image={imageData}
        button={buttonIntro[0]}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imagecontent ? getImage(imagecontent) : imageData}
        imageCredits={image?.description}
      />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    pl: 'Powrót do usług',
    en: 'Back to services',
    zh: '返回服务',
  },
  link: '/services/',
}

export const query = graphql`
  query ($id: String!) {
    service: contentfulService(id: { eq: $id }) {
      id
      slug
      name
      title
      description
      keywords
      createdAt
      updatedAt
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
          layout: FIXED
        )
        file {
          url
          fileName
        }
      }
      imagecontent {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
          layout: FIXED
        )
      }
    }
  }
`
