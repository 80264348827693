import React from 'react'

const Lines = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='617.305'
    height='555.781'
    viewBox='0 0 617.305 555.781'>
    <defs>
      <linearGradient
        id='linear-gradient'
        x1='0.031'
        y1='1.072'
        x2='0.938'
        y2='-0.224'
        gradientUnits='objectBoundingBox'>
            <stop stop-color="#2b2c69" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#2b2c69" stop-opacity="0.15" offset="30%"></stop>
            <stop stop-color="#5E62BD" offset="50%"></stop>
            <stop stop-color="#5E62BD" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      id='Path_41'
      d='M300.7,561.526,462.867,292.964a63.773,63.773,0,0,1,23.961-21.648,69.7,69.7,0,0,1,32.275-7.884H769.2c18.258,0,40.841-12.613,50.349-28.165l4.531-7.4L751.423,110.372H540.649L323.867,459.9a46.682,46.682,0,0,1-39.523,21.259H274.7v-2.439h9.649A44.224,44.224,0,0,0,321.8,458.6L537.775,110.372H414.406A76.473,76.473,0,0,1,374.72,99.336a72.792,72.792,0,0,1-27.5-29.725L310.157,7h2.837l36.379,61.448v.042c11.93,23.965,37.446,39.449,65.012,39.449h124.9L551.4,88.4A52.81,52.81,0,0,1,570.812,70.53a54.767,54.767,0,0,1,25.869-6.507H722.762L687.5,7h2.863l35.26,57.023H797.9L762.761,7h2.889l35.139,57.023h21.82c9.993,0,21.234,3.632,31.085,9.429a51.264,51.264,0,0,1,9.577-.884H892V75H863.15a48.834,48.834,0,0,0-6.3.41c7.557,4.962,14.062,11.189,18.193,17.949l15.678,25.628,1.283-2.1v8.886l-1.289-2.106L864.93,165.791A60.075,60.075,0,0,0,892,181.78v2.529a62.732,62.732,0,0,1-28.391-16.358l-36.675,59.914,11.133,18c8.02,12.614,23.045,20.465,39.2,20.465H892v2.423H877.262c-16.98,0-32.8-8.283-41.267-21.616l-10.483-16.953-3.885,6.347c-9.9,16.135-33.421,29.306-52.43,29.306H519.113c-22.073,0-42.817,10.874-54.132,28.381L302.8,562.782ZM825.5,225.542l36.378-59.434a55.468,55.468,0,0,1-6-8.014L818.565,97.555l-6.609,5.56a31.18,31.18,0,0,1-20.056,7.258H754.283Zm6.393-139.2-11.424,9.611,37.508,60.867a53.1,53.1,0,0,0,5.241,7.1l26.066-42.586L872.953,94.648c-4.369-7.136-11.492-13.662-19.688-18.632A48.78,48.78,0,0,0,831.891,86.344Zm-79.112,21.6H791.9a28.737,28.737,0,0,0,18.479-6.68l6.895-5.8-17.86-28.983H727.141Zm-199.3-18.248L542.157,107.94H749.919L724.28,66.478h-127.6A51.035,51.035,0,0,0,553.475,89.692Zm265.7,4.169,11.143-9.373a51.24,51.24,0,0,1,19.856-10.244c-8.881-4.81-18.723-7.767-27.565-7.767H802.3ZM885.4,50.827v-.042L858.6,7h2.164l26.249,42.89A42.876,42.876,0,0,0,892,57.622V60.55A43.073,43.073,0,0,1,885.4,50.827Z'
      transform='translate(-274.695 -7)'
      fill='url(#linear-gradient)'
    />
  </svg>
)

export default Lines
